import React from 'react';
import Properties from 'views/Properties';
import { SEO as Head } from 'components/seo';

const PropertiesPage = (): JSX.Element => {
  return (
    <>
      <Head />
      <Properties />
    </>
  );
};

export default PropertiesPage;
